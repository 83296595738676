.btnClass {
  background-color: #2856a3;
  color: white;
  border: 0;
  padding: 0px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}
.btnDisable {
  background-color: gray;
  color: white;
  border: 0;
  padding: 0px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}

.containerModalUsuarios {
  text-align: left;
  padding: 10px 20px 30px 20px;
  font-size: 20px;
}

.containerButtonsModalUsuarios {
  text-align: center;
}

.spanBlack {
  font-weight: bolder;
}

#EditUserPasoFinal {
  display: none;
}

#DeleteUserPasoFinal {
  display: none;
}

.checkBoxMargin {
  margin-left: 22px;
}

.labelCheckBoxMargin {
  margin-top: 5px;
}

#UpdatePassUserPasoFinal {
  display: none;
}

#addUserPasoFinal {
  display: none;
}
