/*
.tableContainer {
  height: 400px;
  width: 1250px;
  margin: 0 auto !important;
}
*/
.resumeSellNumber {
  padding: 45px 20px 20px 20px !important;
  text-align: center;
  font-size: 40px;
}

.statsResume {
  font-size: 12px;
}

.bordesredondeados {
  border-radius: 5px 5px 5px 5px !important;
  -moz-border-radius: 5px 5px 5px 5px !important;
  -webkit-border-radius: 5px 5px 5px 5px !important;
  border: 0px solid #000000;
}
