.margintop-10 {
  margin-top: 40px;
}

.paddinTop-13 {
  padding-top: 13px !important;
}

#showResultSearchTicket {
  display: none;
}

.tableResult {
  font-size: 12px;
  border: 1px solid #bdc3c7;
}

.tableResult tr {
  border-bottom: 1px solid #bdc3c7;
}

.tableResult th {
  background-color: #f3f6f6;
  color: rgba(0, 0, 0, 0.54);
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #bdc3c7;
}

.border-left {
  border-left: 0px !important;
}

.tableResult td {
  padding: 5px 10px;
  text-align: center;
  border: 1px solid #bdc3c7;
}

.align-right {
  text-align: right;
}

.float-left {
  float: left;
}

.minWidthValTicket {
  min-width: 80px;
}

.btnBoucherClass {
  background-color: #2856a3;
  color: white;
  border: 0;
  padding: 5px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
  float: right;
  margin-left: 10px;
}

.btnBoucherClassDisable {
  background-color: gray;
  color: white;
  border: 0;
  padding: 5px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
  float: right;
  margin-left: 10px;
}

.toolsTD {
  width: 200px;
}

.marginTop30 {
  margin-top: 30px;
}

.width200 {
  width: 200px;
}

.width500 {
  width: 500px;
}

.btnAccountToolsClass {
  background-color: #2856a3;
  color: white;
  border: 0;
  padding: 0px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
  float: right;
  margin-left: 10px;
}

.containerEditBankAccountModal {
  padding: 20px;
}

.centerButtonContainer {
  text-align: center;
}

#EditBankAccountModalFinal {
  display: none;
}
