.FastAccessContainer {
  height: 420px;
  padding: 20px 0px 0px 0px !important;
}

.fastAccessBTNS {
  margin-top: -30px;
  font-size: 12px;
}

.btnFastAccessblueBack {
  color: white;
  border: 0px;
  margin: 5px 5px 0px 0px;
  padding: 10px 20px;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 0px solid #000000;
  background-color: #2856a3;
  font-weight: bolder;
}

.btnFastAccessorangeBack {
  color: white;
  border: 0px;
  margin: 5px 5px 0px 0px;
  padding: 10px 20px;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 0px solid #000000;
  background-color: #e94a1b;
  font-weight: bolder;
}
