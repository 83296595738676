.centerContent {
  text-align: center;
}

.pagoContainer {
  margin-top: -60px;
  height: 438px;
}

.blackLabel {
  color: #000000 !important;
  font-size: 15px !important;
  font-weight: 600;
  margin-bottom: 20px !important;
}

.paddingTopLabel {
  padding-top: 15px;
}

.pagoLabelMinWith {
  width: 150px;
}

.pagoLabelMinWithChico {
  width: 103px;
}

.paddingLabel {
  padding-left: 20px;
}

.col-without-padding {
  width: 100%;
  padding: 0 !important;
}

.float-left {
  float: left;
}

#step-pago {
  display: none;
}

#step-previewventa {
  display: none;
  height: 445px;
}

#step-printQR {
  display: none;
}

.color-black-bolder {
  font-weight: bolder;
  color: black !important;
}

.Modal {
  position: absolute;
  width: 400px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(163, 163, 163, 0.6);
}

.headermodal {
  width: 100%;
  height: 30px;
}

.headermodal button {
  float: right;
  width: 30px;
  height: 30px;
  font-size: 20px;
  padding-top: 4px;
  color: white;
  font-weight: bolder;
  background-color: #2856a3;
  border: 0;
  margin-top: -15px;
  margin-right: -15px;
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
}

.containerModal {
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}

.containerModal img {
  margin: 20px;
}

#authCodeTransbankContainer {
  display: none;
}

.warningMsg {
  color: red !important;
  font-size: 12px;
}

.paddingExtra {
  padding: 0px 30px;
  margin-left: 10px;
}

.btnPagarLittle {
  font-size: 20px !important;
  padding: 10px 20px !important;
}

.blackSelect {
  color: black !important;
  font-size: 16px !important;
}
