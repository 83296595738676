.toolsSellContainer {
	padding: 0px 20px;
}

.toolSell {
	float: left;
	margin-top: 18px;
	margin-right: 30px;
}

.switchLabel {
	float: left;
	padding-top: 4px;
	padding-right: 10px;
}

.toolsSellCloseBox {
	float: right;
}

.containerModalForm {
	padding: 20px;
}

.containerModalForm h4 {
	text-align: left;
	padding-left: 10px;
	margin-bottom: 30px;
}

.containerModalForm h5 {
	text-align: left;
	margin-bottom: 20px;
}

.buttonModalForm {
	text-align: center !important;
}

.msgLoginVenta {
	text-align: center;
}

#paso2cierrecaja {
	display: none;
}

#paso2cierrecaja p {
	padding: 20px 0px 0px 0px;
}

#cajaNOCuadra {
	display: none;
}

.p_comentario {
	padding: 0px 0px 20px 0px !important;
}

#paso3cierrecaja {
	display: none;
}

.cuadraturaResponse {
	width: 100%;
	text-align: center;
}

.cuadraturaResponse img {
	margin-bottom: 20px;
	margin-top: 40px;
}

.cuadraturaResponse p {
	margin-bottom: 40px;
	font-weight: bolder;
	font-size: 20px;
}

.divNone {
	display: none;
}
