.btnAlignRight {
  text-align: right;
}

.btnonRow {
  margin-top: 0px;
}

.paddingLabelDesc {
  padding-top: 15px;
}

.blackLabel {
  font-weight: bolder;
}

.paddingSuperior {
  padding: 12px 20px;
}

.paddingLabelSinLimit {
  padding-top: 5px;
}

.colorWhite {
  color: #ffffff;
}
