.ButtonsBar {
	width: 100%;
	text-align: center;
	margin-top: -30px;
}

.btn-inactivo {
	background-color: #2856a3;
	color: #ffffff;
}

.css-1hwfws3 {
	padding: 7px 0px 7px 10px !important;
}
