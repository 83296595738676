.containerGenCounters {
  padding: 20px;
}

.counterContainer {
  width: 100%;
  min-height: 200px;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
  float: left;
}

.boxesContainer {
  width: 100%;
  float: left;
}

.boxData {
  width: 550px;
  min-height: 150px;
  float: left;
  margin-bottom: 30px;
  margin-right: 50px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
}

.tdTableCajas {
  border: 1px solid #bdc3c7;
  padding: 5px 10px;
}

.titleTable {
  background-color: #f3f6f6;
  width: 195px;
  font-weight: bolder;
}

.tableBottomTools {
  padding: 20px;
  border: 1px solid #bdc3c7;
}

.tableContentTD {
  width: 350px;
}

.TableBotonera {
  text-align: center;
}

.containerCajasModal {
  padding: 2px 20px 2px 20px;
}

.containerCajasModal h5 {
  margin-bottom: 20px;
}

.botoneraModalCaja {
  text-align: center;
}

#AddSupObsModalFinal {
  display: none;
}
