.btn-logout {
  font-weight: bolder !important;
}

.personnameHeader {
  margin-right: 40px !important;
}

.modalblock {
  z-index: 100000 !important;
  background-color: rgba(0, 0, 0, 0.49);
}

.modalBlockContent {
  padding: 40px;
}

.modalBlockHeader {
  padding: 30px 0px 5px 0px;
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
}

.modalFooter {
  text-align: center;
}

.boldLabel {
  font-weight: 600;
  margin-right: 10px;
}

.msgBlockLogin {
  text-align: center;
  padding: 0px 0px 30px 0px;
}

.marginLeft100 {
  margin-left: 100px;
}

.contentModalCloseSession {
  padding: 40px;
}

.principalContentCloseSession {
  font-weight: bolder;
  text-align: center;
}

.explainCloseSession {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.botoneraCloseSession {
  margin-top: 40px;
  text-align: center;
}

.avisoCierre {
  font-size: 13px;
  margin-top: -15px;
  margin-bottom: 25px;
}
