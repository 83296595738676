.ticketTitle {
  text-align: center;
  display: none;
}

.ticketTitle img {
  width: 60%;
}

.TicketPaper {
  width: 350px !important;
  margin: 0 auto;
  border: 1px solid black;
  padding: 10px 20px;
}

.qrviewstyle {
  text-align: center;
  padding-top: 30px;
  display: none;
}

.TicketDetail {
  padding: 20px 0px;
  font-size: 12px;
}

.labelPreviewTicket {
  padding: 10px 0px;
  font-weight: bolder;
  width: 120px;
}

.valuePreviewTicket {
  padding: 10px 0px 10px 10px;
}
