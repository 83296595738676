.margintop-10 {
	margin-top: 40px;
}

.paddinTop-13 {
	padding-top: 13px !important;
}

#showResultSearchTicket {
	display: none;
}

.tableResult {
	font-size: 12px;
	border: 1px solid #bdc3c7;
}

.tableResult tr {
	border-bottom: 1px solid #bdc3c7;
}

.tableResult th {
	background-color: #f3f6f6;
	color: rgba(0, 0, 0, 0.54);
	padding: 5px 10px;
	text-align: center;
	border: 1px solid #bdc3c7;
}

.border-left {
	border-left: 0px !important;
}

.tableResult td {
	padding: 5px 10px;
	text-align: center;
	border: 1px solid #bdc3c7;
}

.align-right {
	text-align: right;
}

.minWidthValTicket {
	min-width: 80px;
}

.btnBoucherClass {
	background-color: #2856a3;
	color: white;
	border: 0;
	padding: 5px 20px 5px 20px;
	font-size: 10px;
	font-weight: bolder;
}

.btnBoucherClassDisable {
	background-color: gray;
	color: white;
	border: 0;
	padding: 5px 20px 5px 20px;
	font-size: 10px;
	font-weight: bolder;
}

.btnBoucherClass:disabled {
	background-color: gray;
	color: white;
	border: 0;
	padding: 5px 20px 5px 20px;
	font-size: 10px;
	font-weight: bolder;
}

#CambioEstadoFinal {
	display: none;
}

.containerConductorModal {
	width: 100%;
	padding: 10px;
}

.ModalConductor {
	position: absolute;
	width: 700px;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background-color: white;
}

#infoconductor > table {
	font-size: 14px;
	margin: 0 auto;
}

#infoconductor > table > thead > tr > th {
	padding: 5px;
	border: 1px solid #bdc3c7;
	background-color: #f3f6f6;
}

#infoconductor > table > tbody > tr > td {
	padding: 5px;
	border: 1px solid #bdc3c7;
}
