.btnBoucherClass {
  background-color: #2856a3;
  color: white;
  border: 0;
  padding: 5px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}

.btnBoucherClassDisable {
  background-color: gray;
  color: white;
  border: 0;
  padding: 5px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}

#AnularTicketPeticionFinal {
  display: none;
}

.validaUsuarioCreado {
  color: red;
}
