.btnAnularClass {
  background-color: #2856a3;
  color: white;
  border: 0;
  padding: 0px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}
.btnAnularClassDisable {
  background-color: gray;
  color: white;
  border: 0;
  padding: 0px 20px 5px 20px;
  font-size: 10px;
  font-weight: bolder;
}

.containerAnulacionModal {
  text-align: center;
  padding: 10px 20px 30px 20px;
  font-size: 20px;
}

.spanBlack {
  font-weight: bolder;
}

#finalStepTranbank {
  display: none;
}

#PetAnulFinal {
  display: none;
}
