.reportsTypeContainer {
	padding: 0px 15px;
}

.tableResult {
	font-size: 12px;
	border: 1px solid #bdc3c7;
}

.tableResult tr {
	border-bottom: 1px solid #bdc3c7;
}

.tableResult th {
	background-color: #f3f6f6;
	color: rgba(0, 0, 0, 0.54);
	padding: 5px 10px;
	text-align: center;
	border: 1px solid #bdc3c7;
}

.border-left {
	border-left: 0px !important;
}

.tableResult td {
	padding: 5px 10px;
	text-align: center;
	border: 1px solid #bdc3c7;
}

.align-right {
	text-align: right;
}

.minWidthValTicket {
	min-width: 80px;
}

#reportsTableResults {
	display: none;
}

#vendedoresListContainer {
	width: 100%;
	float: left;
	display: none;
}

#conductoresListContainer {
	width: 100%;
	float: left;
	display: none;
}

.exportExcelContainer {
	width: 100%;
	float: left;
	height: 60px;
	text-align: right;
}

.btnBoucherClass {
	background-color: #2856a3;
	color: white;
	border: 0;
	padding: 5px 20px 5px 20px;
	font-size: 10px;
	font-weight: bolder;
}

.btnBoucherClassDisable {
	background-color: gray;
	color: white;
	border: 0;
	padding: 5px 20px 5px 20px;
	font-size: 10px;
	font-weight: bolder;
}

#CambioEstadoTicketReportFinal {
	display: none;
}

.width100 {
	width: 100%;
	padding-left: 20px;
}

.margintop5 {
	margin-top: 5px;
}

.marginleft20 {
	margin-left: 20px;
}

.marginleft40 {
	margin-left: 40px;
}

.exportExcelContainerNomina {
	width: 100%;
	float: left;
	height: 60px;
	text-align: center;
}

.exportExcelContainerNominaTable {
	width: 100%;
	float: left;
	height: 60px;
	text-align: right;
}

#exportExcelNomina {
	display: none;
}

.tableCajasReport {
	text-align: left;
	float: left;
}

#tableCajasReportContainer {
	display: none;
}

.TableTicketsParaNomina {
	width: 100%;
	float: left;
	margin-top: 40px;
}

.TableTicketsParaNomina h4 {
	padding-left: 70px;
	margin-bottom: -40px;
}

#ticketsParaNominaTable {
	display: none;
}

.listaTicketsParaNomina {
	text-align: right;
	padding-right: 70px;
}

#pasaraPagoContainer {
	display: none;
}

.centerContent {
	text-align: center;
}

#transparent {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(202, 202, 202, 0.76);
	z-index: 10;
}

#pasarapagomsg {
	display: none;
}

.pasarapagocontainer {
	width: 300px;
	height: 200px;
	position: fixed;
	top: calc(50% - 150px);
	left: calc(50% - 100px);
	background-color: white;
	text-align: center;
	padding-top: 50px;
	z-index: 11;
	font-weight: bold;
}

.pasarapagocontainer > img {
	width: 30%;
}

.listticketasign {
	padding: 10px !important;
}

.ticketsasignform {
	padding: 10px !important;
}
