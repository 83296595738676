.paddingBigChart {
  padding: 0px 40px 40px 40px;
}

.padding40 {
  padding: 40px;
}

.paddingLados {
  padding: 0px 40px;
}

.TicketsCounterDash {
  width: 100%;
  height: 100%;
  padding: 40px;
  /*border: 1px solid gray;*/
  -webkit-box-shadow: 10px 10px 15px -1px rgba(207, 207, 207, 1);
  -moz-box-shadow: 10px 10px 15px -1px rgba(207, 207, 207, 1);
  box-shadow: 10px 10px 15px -1px rgba(207, 207, 207, 1);
}

.contadorTickets {
  text-align: center;
  padding-top: 20px;
  font-size: 160px !important;
  color: #74df00;
}
