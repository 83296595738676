.labelNotSelectSearch {
  padding-top: 5px;
  padding-bottom: 0px;
}

.select_ta {
  background-color: #ffffff !important;
  margin-top: 5px;
}

.padding-top-32 {
  padding-top: 32px;
}

.padding-top-26 {
  padding-top: 26px;
}

.padding-top-20 {
  padding-top: 20px;
}

.margin-bottom-33 {
  margin-bottom: 33px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#inicio_diff {
  display: none;
}

.o_separador {
  width: 100%;
  text-align: center;
  margin: 10px 0px;
  font-weight: bolder;
}

#OpcionDireccion {
  display: none;
}

.row-100 {
  width: 100%;
}

#selectTarifaDir {
  height: 420px;
}

#mapcontainer {
  text-align: center;
  margin-top: 0px;
  height: 282px;
}

.montotext {
  font-size: 50px;
  color: green;
  text-align: center;
  /*margin-top: -50px;*/
}

#step-monto {
  display: none;
}

.radioBTNSelected {
  padding: 10px 25px;
  font-size: 15px !important;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 4px solid #e94a1b;
  background-color: #e94a1b;
  color: white !important;
  font-weight: bolder;
}

.radioBTNInactive {
  padding: 10px 25px;
  font-size: 15px !important;
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 4px solid #c5c5c5;
  background-color: #c5c5c5;
  color: white !important;
  font-weight: bolder;
}

.btnPago {
  padding: 20px 35px;
  font-size: 20px !important;
}

.upDiv10 {
  margin-top: -10px;
}

.upDiv20 {
  margin-top: -20px;
}

.margintop10 {
  margin-top: 10px;
}

.margintop5 {
  margin-top: 5px;
}
